@import '~bootstrap/scss/bootstrap';
@import 'fonts';
@import 'colors';
@import 'misc';
@import 'typography';
@import 'home';
@import 'amphores';
@import 'pages';
@import 'accordion';

body {
  font-family: "AvenirNextLTPro", sans-serif;
  background-color: #000;
  font-weight: normal;
  color: #fff;
  font-size: 20px;
}

