@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../fonts/AvenirNextLTPro-Bold.eot');
  src: url('../fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
  url('../fonts/AvenirNextLTPro-Bold.woff') format('woff'),
  url('../fonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
  url('../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../fonts/AvenirNextLTPro-Regular.eot');
  src: url('../fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
  url('../fonts/AvenirNextLTPro-Regular.woff') format('woff'),
  url('../fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
  url('../fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
