.mrn-btn {
  border: 2px solid $accent-color;
  display: inline-block;
  align-self: flex-start;
  padding: 8px 25px;
  border-radius: 35px;
  font-weight: bold;
  color: $accent-color;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.no-border {
  border: none !important;
}

.full-link {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  padding: 75px 40px;
  text-decoration: none
}

.home-link {
  height: 72px;
  width: 72px;
  background-color: #fff;
  border-radius: 72px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  justify-self: flex-start;
  position: absolute;
  right: -35px;
  top: 50px;
  z-index: 20;
  img {
    display: flex;
    align-self: center;
  }
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.no-italics {
  font-style: normal;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
