
h1 {
  color: $accent-color;
  font-size: 72px;
  font-weight: 600;
}

h2 {
  position: relative;
  font-size: 56px;
  font-weight: 600;
  &:before {
    background-image: url('../img/title-line.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    position: absolute;
    left: -25px;
    width: 15px;
    height: 130px;
    top: 0;
  }
}
h3 {
  font-size: 48px;
  font-weight: 600;
}

h4 {
  font-size: 26px;
  font-weight: 600;
}
